import { signal, WritableSignal } from '@angular/core';

export const LoaderStatusState = {
  FAILED: 'FAILED',
  PENDING: 'PENDING',
  RESOLVED: 'RESOLVED',
  IDLE: 'IDLE',
} as const;

export type LoaderStatusState = (typeof LoaderStatusState)[keyof typeof LoaderStatusState];

export class LoaderStatus {
  get status() {
    return this.#status();
  }

  #status: WritableSignal<LoaderStatusState>;
  #initialState: LoaderStatusState;

  constructor(initialStatus: LoaderStatusState = LoaderStatusState.IDLE) {
    this.#initialState = initialStatus;
    this.#status = signal(this.#initialState);
  }

  start(): void {
    this.#status.set(LoaderStatusState.PENDING);
  }

  reject(): void {
    this.#status.set(LoaderStatusState.FAILED);
  }

  resolve(): void {
    this.#status.set(LoaderStatusState.RESOLVED);
  }

  reset(): void {
    this.#status.set(this.#initialState);
  }

  isResolved(): boolean {
    return this.#status() === LoaderStatusState.RESOLVED;
  }

  isFailed(): boolean {
    return this.#status() === LoaderStatusState.FAILED;
  }

  isPending(): boolean {
    return this.#status() === LoaderStatusState.PENDING;
  }

  isIdle(): boolean {
    return this.#status() === LoaderStatusState.IDLE;
  }
}
