import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  standalone: true,
  name: 'sortWith',
})
export class SortWithPipe implements PipeTransform {
  transform<T>(items: T[], compareFn: (a: T, b: T) => number): T[] {
    if (!Array.isArray(items)) {
      return items;
    } else {
      return [...items].sort((a, b) => compareFn(a, b));
    }
  }
}
