import { Pipe, PipeTransform } from '@angular/core';
import { Entity } from '../models';

@Pipe({
  standalone: true,
  name: 'without',
})
export class WithoutPipe implements PipeTransform {
  transform<T extends Entity>(entities: T[], args: T[]): T[] {
    const ids = args.map((entity) => entity.id);
    return entities.filter((e) => !ids.includes(e.id));
  }
}
