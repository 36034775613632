import { AbstractControl, ValidationErrors, Validators } from '@angular/forms';

export class WelmaValidators {
  static descriptionNotTooLong(control: AbstractControl): ValidationErrors | null {
    return Validators.maxLength(300)(control);
  }

  /**
  A validator that requires the control's value to be a date in the 'YYYY-MM-DD' format
  */
  static isoFullDate(control: AbstractControl): ValidationErrors | null {
    const validationErrors = Validators.pattern('^(\\d{4})-(0[1-9]|1[0-2])-([0-2][0-9]|3[0-1])$')(
      control,
    );
    if (validationErrors === null) {
      return null;
    }
    return { isoFullDate: true };
  }

  /**
   A validator for a school year name : should be XXXX - YYYY with YYYY = XXXX + 1
   */
  static schoolYearName(control: AbstractControl): ValidationErrors | null {
    const validationErrors = Validators.pattern('^(\\d{4})[ ]-[ ](\\d{4})$')(control);
    if (validationErrors !== null) {
      return { nameIsInvalid: true };
    }
    const years = control.value.replace(/\s/g, '');
    const year1 = Number(years.split('-')[0]);
    const year2 = Number(years.split('-')[1]);
    if (year2 !== year1 + 1) {
      return { nameIsInvalid: true };
    }
    return null;
  }
}
