import { debounceTime, distinctUntilChanged, Observable, skip, startWith } from 'rxjs';
import { map } from 'rxjs/operators';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { assertInInjectionContext, inject } from '@angular/core';

export function injectQueryParam(name: string, debounce = 0): Observable<string> {
  assertInInjectionContext(injectQueryParam);
  const route = inject(ActivatedRoute);
  const initialValue = getQueryParam(route.snapshot.queryParamMap, name);
  return route.queryParamMap.pipe(
    skip(1),
    debounceTime(debounce),
    map((queryParamMap) => getQueryParam(queryParamMap, name)),
    startWith(initialValue),
    distinctUntilChanged(),
  );
}

function getQueryParam(paramMap: ParamMap, name: string): string {
  return paramMap.get(name) ?? '';
}

export function injectQueryParamArray(name: string, debounce = 0): Observable<string[]> {
  assertInInjectionContext(injectQueryParam);
  const route = inject(ActivatedRoute);
  const initialValue = getQueryParamArray(route.snapshot.queryParamMap, name);
  return route.queryParamMap.pipe(
    skip(1),
    debounceTime(debounce),
    map((queryParamMap) => getQueryParamArray(queryParamMap, name)),
    startWith(initialValue),
    distinctUntilChanged(),
  );
}

export function getQueryParamArray(paramMap: ParamMap, name: string): string[] {
  return paramMap.getAll(name) ?? [];
}
