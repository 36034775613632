import { FormControl } from '@angular/forms';
import { Observable, startWith, tap } from 'rxjs';

export function valueChanges<T>(
  first: FormControl<T>,
  second?: FormControl<unknown>,
): Observable<T> {
  return first.valueChanges.pipe(
    // Reset the value of the second FormControl whenever the first FormControl changes
    tap(() => {
      if (second?.value) {
        second.reset();
        second.markAsDirty();
      }
    }),
    // Start the stream by emitting the current value of the first FormControl
    startWith(first.value),
  );
}
