export class WelmaError {
  readonly type: string = 'SERVER';
  readonly status?: number;
  readonly code?: string;
  readonly message?: string;

  constructor(init: { type?: string; status?: number; code?: string; message?: string }) {
    this.type = init.type || this.type;
    this.status = init.status;
    this.code = init.code;
    this.message = init.message;
  }
}
