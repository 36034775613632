/**
 * @description download a blob.
 * @param blob The blob to download
 * @param name The name of the file to download
 */

export function downloadBlob(blob: Blob, name: string) {
  const a = document.createElement('a');
  a.download = name;
  a.rel = 'noopener';
  a.href = URL.createObjectURL(blob);

  a.click();

  setTimeout(function () {
    URL.revokeObjectURL(a.href);
  }, 6e4); // 60s
}
