import { catchError, map, Observable, of, startWith } from 'rxjs';
import { toSignal } from '@angular/core/rxjs-interop';
import { Signal } from '@angular/core';
import { LoaderStatusState } from './loader-status';

export type RequestResolved<T = unknown> = {
  status: typeof LoaderStatusState.RESOLVED;
  data: T;
};

export type RequestFailed = {
  status: typeof LoaderStatusState.FAILED;
  error: unknown;
};

export type RequestPending = {
  status: typeof LoaderStatusState.PENDING;
};

export type RequestWithLoader<T> = RequestResolved<T> | RequestFailed | RequestPending;

export function requestWithLoader<T>(request$: Observable<T>): Observable<RequestWithLoader<T>> {
  return request$.pipe(
    map((data): RequestResolved<T> => {
      return { status: LoaderStatusState.RESOLVED, data };
    }),
    catchError((error): Observable<RequestFailed> => {
      return of({ status: LoaderStatusState.FAILED, error });
    }),
    startWith({
      status: LoaderStatusState.PENDING,
    } as RequestPending),
  );
}

export function requestWithLoaderSignal<T>(request$: Observable<T>): Signal<RequestWithLoader<T>> {
  return toSignal(requestWithLoader(request$), { requireSync: true });
}
