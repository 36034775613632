import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  FormRecord,
  ValidatorFn,
} from '@angular/forms';
import { Entity, EntityFormGroup } from '../models';

/*
  Creates a FormRecord like {'uuid-1': false, 'uuid-2': false}
  where the key is the id of the entity and the value is `true` if selected in the checkbox and `false` if not
*/

export function createSelectedEntitiesRecord(
  entities: Entity[],
  selectedEntities: Entity[] = [],
): FormRecord<FormControl<boolean>> {
  const formRecord = new FormRecord<FormControl<boolean>>({});
  const selectedIds = selectedEntities.map((entity) => entity.id);
  entities.forEach((entity) => {
    if (entity.id) {
      const checked = selectedIds.includes(entity.id);
      formRecord.addControl(entity.id, new FormControl(checked, { nonNullable: true }));
    }
  });
  return formRecord;
}

export function createEntityFormGroup(
  entity: Entity,
  validators: ValidatorFn[] = [],
): FormGroup<{ id: FormControl<string> }> {
  return new FormBuilder().nonNullable.group({
    id: [entity.id, validators],
  });
}

export function getSelectedEntitiesFromFormValue(formValue: Record<string, boolean>): Entity[] {
  return Object.keys(formValue)
    .filter((id) => formValue[id])
    .map((id) => ({ id }));
}

export function createEntityFormArray(entities: Entity[]): FormArray<EntityFormGroup> {
  const controls = entities.map((entity) => createEntityFormGroup(entity));
  return new FormArray<EntityFormGroup>(controls);
}
