/**
 * @description Sort items by their name property.
 * @param items the list of items to sort
 * @param order the sort order. By default, 'asc'
 */

export function sortByName<T extends { name: string }>(
  items: T[],
  order: 'asc' | 'desc' = 'asc',
): T[] {
  const newItems = [...items].sort((a, b) => (b.name < a.name ? 1 : -1));

  switch (order) {
    case 'asc':
      return newItems;
    case 'desc':
      return newItems.reverse();
  }
}
