import { LoaderStatus } from './loader-status';
import { tap } from 'rxjs';

export function resolveOrRejectLoader<T>(loader: LoaderStatus) {
  return tap<T>({
    next: () => loader.resolve(),
    error: () => loader.reject(),
  });
}

export function startLoader<T>(loader: LoaderStatus) {
  return tap<T>(() => loader.start());
}
